import React, { useEffect, useState  } from "react";
import { Link } from "gatsby";

import styles from "./breadbrumb.module.scss";
import { withNamespaces } from "react-i18next";
const Breadcrumb = props => {
  const { t, arrPath } = props;
 
  const [host, setHost] = useState(""); 


  useEffect(() => {
    setHost(typeof window !== 'undefined' ?window.location.protocol + "//" + window.location.hostname  : '');  

  }, []);

  return (
    <div className={styles.breadcrumb}>
      <Link to={host}>{t("home")}</Link>

      {arrPath.map(row => {
        return (
          <>
            <span className={styles.separator}>/</span>
            {row.path !== null ? (
              <Link to={host + row.path}>{t(row.label)}</Link>
            ) : (
              <span> {t(row.label)}</span>
            )}
          </>
        );
      })}
    </div>
  );
};
export default withNamespaces()(Breadcrumb);
