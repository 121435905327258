import moment from "moment";

export var queryDetail = (
  page,
  pageSize,
  tableName,
  valueType,
  period,
  startDate,
  endDate
) => {
  if (startDate === null || endDate === null) {
    var parameters = 'period:"' + period + '"';
  } else {
    var parameters = 'startDate:"' + startDate + '", endDate:"' + endDate + '"';
  }

  return `query homeInfo {
        detail(page: ${page}, pageSize: ${pageSize}, tableName: "${tableName}", valueType: "${valueType}" , ${parameters}) {
          totalPages
          totalRecords
          data {
            id
            date
            month
            price
            quarter
            rate
            settlement
            stock
            three_month
            year
          }
        
      }
    }
    `;
};

export const dateFormat = dateString => {
  if (dateString != null) {
    var arrDate = dateString.split("-");
    arrDate[1] = monthName(arrDate[1]);
    return arrDate.join(" ");
  }
  return dateString;
};

export const monthName = number => {
  const names = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  return names[number - 1];
};

export const numberFormat = n => {
  try {
    return n
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } catch (Any) {
    return "0.00";
  }
};

export const formatDateForTable = timestamp => {
  if (timestamp) {
    let dateObj = new Date(timestamp);
    return moment(dateObj).format("DD-MM-YYYY");
  }
  return null;
};

export const formatNumberMarktdatenTables = (language, num, amountDigits) => {
  if (isNaN(num)) return num;
  var languageSymbol = language === "en" ? "en-EN" : "de-DE";
  return new Intl.NumberFormat(languageSymbol, {
    style: "decimal",
    minimumFractionDigits: amountDigits,
  }).format(num);
};
export const formatDate = (dateString, language, numericMonth) => {
  try {
    if (dateString !== null) {
      var languageSymbol = language === "en" ? "en-EN" : "de-DE";

      var options = {
        year: "numeric",
        month: numericMonth === true ? "numeric" : "long",
        day: "numeric",
      };
      return new Intl.DateTimeFormat(languageSymbol, options).format(
        new Date(dateFormat(dateString))
      );
    }
  } catch (Any) {
    console.log(dateString);
  }

  return dateString;
};
export const prepareDateForQuery = timestamp => {
  if (timestamp !== null) {
    let dateObj = new Date(timestamp);
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    return year + "-" + month + "-" + day;
  }
  return null;
};
export const columnNames = table => {
  const mapping = {
    Euro_MTLE: "LME-FX-Rate (MTLE)",
    Euro_EZB: "EZB Fixing",
    Euro_FX1: "EUR/USD DEL-Notiz",
    DEL_low: "untere DEL-Notiz",
    DEL_high: "obere DEL-Notiz",
    MK_Cu: "MK-Kupfer",
    Pb_Cable: "Blei in Kabeln",
    MB_bronze_94_6: "Bronze 94/6",
    Al_unalloyed: "Leitaluminium",
    Al_cables: "Aluminium in Kabeln",
    MB_MS_58_1: "MS 58 1. V. Stufe",
    MB_MS_58_2: "MS 58 2. V. Stufe",
    MB_MP_58: "MP 58",
    MB_MS_63_wire: "MS 63, Draht",
    MB_MS_63_37: "MS 63/37",
  };
  return mapping[table];
};
