import React from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "gatsby";
import styles from "./marktdatenTable.module.scss";

const LinkToSource = props => {
  const { t, to } = props;
  if (!to) {
    return null;
  }

  return (
    <Link className={styles.sourceLink} to={to} target="_blank">
      {/* {t("LME_Source")} */}
      {t("*Quelle")}
    </Link>
  );
};
export default withNamespaces()(LinkToSource);
